<template>
  <App
    title="修改配置"
    left-arrow
  >
    <div class="container">
      <VehicleModel
        ref="vehicleModel"
        :carTypeCode="detailData.carTypeCode"
        :carSeriesCode="detailData.carSeriesCode"
        :featureCodeList="featureCodeList"
        @on-price-change="(price) => this.totalPrice = price"
      />
    </div>
    <Footer
      slot="footer"
      :step="0"
      :maxStep="0"
      :totalPrice="totalPrice"
      @on-save="onSave"
      @on-cancel="$page.close()"
    />
  </App>
</template>

<script>
import VehicleModel from '_c/business/vehicleModel'
import Footer from '../createOrder/components/footer'
import { alterconfigure } from '@/api/order'
import { Toast } from 'vant'

export default {
  name: 'modifyConfig',
  components: {
    VehicleModel,
    Footer
  },
  data () {
    return {
      totalPrice: 0,
      detailData: {},
      featureCodeList: []
    }
  },
  methods: {
    onSave () {
      Toast.loading({
        message: '请求中...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      this.$refs.vehicleModel.validate().then(data => {
        const params = {
          orderNum: this.detailData.orderNum,
          channel: this.detailData.channel,
          uid: this.detailData.uid,
          superId: this.detailData.superId,
          consultantId: this.detailData.consultantId,
          consultantName: this.detailData.consultantName,
          carSeriesCode: data.carSeriesCode,
          carTypeCode: data.carType.carTypeCode,
          vehicleCode: data.vehicleInfo.vehicleCode,
          carColorCode: data.outColorList[0].featureCode,
          featureList: data.featureList,
          price: data.carType.price,
          invoicePrice: data.vehicleInfo.invoicePrice,
          acutalPrice: data.vehicleInfo.invoicePrice,
          discountUniteTotal: data.carType.discountPrice
        }
        alterconfigure(params).then(res => {
          if (res.success) {
            Toast('修改成功')
            this.$router.go(-1)
          } else {
            Toast('提交失败，请稍后重试！')
          }
        }).finally(() => {
          Toast.clear()
        })
      })
    }
  },
  mounted () {
    const pageParam = this.$route.query || {}
    this.detailData = { ...pageParam }
    this.featureCodeList = pageParam.featureList.map(e => e.featureCode)
    this.$refs.vehicleModel.init()
  }
}
</script>
<style lang="less" scoped>
/deep/#container {
  height: 100vh;
}
.container {
  position: relative;
  // padding-top: 16px;
  height: 100%;
}
.vehicle {
  top: 0;
  padding: 40px 23px;
}
</style>
